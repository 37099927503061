import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuardService } from './auth/services/auth-guard.service';


export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./entry/entry.module').then(
        m => m.EntryModule
      )
  },
  {
    path: 'planeador',
    loadChildren: () =>
      import('./iplanner-root/iplanner-root.module').then(
        m => m.IplannerRootModule
      ), 
      canActivate: [AuthGuardService]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    enableTracing: false,
    useHash: true,
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
