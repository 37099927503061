export const environment = {
  production: true,
  BASE_URL: 'https://iplanner-services-pro.leonisa.com',
  BASE_URL2: 'https://iplanner-services-pro.leonisa.com',
  BASE_URL_ETL: 'https://iplanner-services-pro.leonisa.com',
  SITE_KEY: '6LfG_HYaAAAAAEO4tHpCjc7jbTLZZYsN-bI1vuyA',
  //SITE_KEY: '6LdqsFcaAAAAAJB36zQsmUanS3akG-MULSqA5C-9',
  TOKEN_KEY: 'access_token',
  USER_KEY: 'auth-user',
};
