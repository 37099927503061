import {
  HttpErrorResponse,
  HttpEvent, HttpHandler,
  HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TokenStorageService } from '../services/token-storage.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(private tokenStorageService: TokenStorageService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const currentCountry = JSON.parse(localStorage.getItem('country_selected'));

    let token = '';
    if (this.tokenStorageService.getToken() && this.tokenStorageService.getUser()) {
      token = `Bearer ${this.tokenStorageService.getToken()} ${this.tokenStorageService.getUser().username}`;
    }

    request = request.clone({
      setHeaders: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "POST,GET",
        "Access-Control-Allow-Methods": "POST",
        'Code-Country': currentCountry ? btoa(currentCountry.code) : '',
        Authorization: token
      }
    });

    const errr = (error: HttpErrorResponse) => {
      return throwError(error);
    };

    return next.handle(request).pipe(
      catchError(errr)
    );
  }
}
