import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent {

  constructor(private primeNGConfig: PrimeNGConfig) {
  }

  ngOnInit() {
    this.primeNGConfig.setTranslation({
      'startsWith': 'Empieza por',
      'contains': 'Contiene',
      'notContains': 'No contiene',
      'endsWith': 'Termina con',
      'equals': 'Es igual a',
      'notEquals': 'No es igual a',
      'noFilter': 'Sin filtro',
      'lt': 'Menor que',
      'lte': 'Menor o igual a',
      'gt': 'Mayor que',
      "gte": "Mayor o igual a",
      'is': 'Es',
      'isNot': 'No es',
      'clear': 'Limpiar',
      'apply': 'Aplicar',
      'matchAll': 'Coincidir con todos',
      'matchAny': 'Coincide con cualquiera',
      'addRule': 'Agregar regla',
    });
  }

}
