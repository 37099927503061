import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { TokenStorageService } from "../../../../src/app/auth/services/token-storage.service";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class IndicatorsService {
  constructor(
    private http: HttpClient,
    private tokenStorageService: TokenStorageService
  ) { }

  GetCampaigns(): Observable<any> {
    return this.http
      .post<any>(
        environment.BASE_URL + `/indicators/global/campaign`,
        null
      )
      .pipe(
        retry(0),
        catchError((e) => {
          if (e && e.text) {
            return e.text.replace("'", '"');
          } else {
            return this.errorHandl(e);
          }
        })
      );
  }

  GetVariablesEstudio(): Observable<any> {
    return this.http
      .post<any>(
        environment.BASE_URL + `/indicators/consolidated/study_variable`,
        null
      )
      .pipe(retry(0), catchError(this.errorHandl));
  }

  GetFilters(req: any): Observable<any> {
    return this.http
      .post<any>(
        environment.BASE_URL + `/indicators/global/filters`,
        req
      )
      .pipe(retry(0), catchError(this.errorHandl));
  }

  GetFiltersGraph(req: any): Observable<any> {
    return this.http
      .get<any>(
        environment.BASE_URL + `/indicators/filters_path/?route=${req}`
      )
      .pipe(retry(0), catchError(this.errorHandl));
  }

  GetIndicatorsConsolidated(request): Observable<any> {
    return this.http
      .post<any>(
        environment.BASE_URL2 + `/indicators/consolidated`,
        JSON.stringify(request)
      )
      .pipe(retry(0), catchError(this.errorHandl));
  }

  GetIndicatorsGraph(req: any): Observable<any> {
    return this.http
      .post<any>(
        environment.BASE_URL2 + `/indicators/consolidated/graph`,
        req
      )
      .pipe(retry(0), catchError(this.errorHandl));
  }

  getTable(req: any): Observable<any> {
    return this.http
      .post<any>(
        environment.BASE_URL2 + `/indicators/consolidated/table`,
        req
      )
      .pipe(retry(0), catchError(this.errorHandl));
  }

  getIncreaseGraph(req: any): Observable<any> {
    return this.http
      .post<any>(
        environment.BASE_URL2 + `/indicators/increase/graph`,
        req
      )
      .pipe(retry(0), catchError(this.errorHandl));
  }

  getIncreaseTable(req: any): Observable<any> {
    return this.http
      .post<any>(
        environment.BASE_URL2 + `/indicators/increase/table`,
        req
      )
      .pipe(retry(0), catchError(this.errorHandl));
  }

  getListado(req: any): Observable<any> {
    return this.http
      .post<any>(environment.BASE_URL + `/indicators/global/list`, req)
      .pipe(retry(0), catchError(this.errorHandl));
  }

  getDifferences(req: any): Observable<any> {
    return this.http
      .post<any>(
        environment.BASE_URL2 + `/indicators/difference/table`,
        req
      )
      .pipe(retry(0), catchError(this.errorHandl));
  }

  getInitialFilter(req: any): Observable<any> {
    let campaign = {
      campaign: Number(req),
    };
    campaign["user"] = this.tokenStorageService.getUser().username;
    return this.http
      .post<any>(
        environment.BASE_URL + `/indicators/global/filters/initialize`,
        campaign
      )
      .pipe(retry(0), catchError(this.errorHandl));
  }

  getIndicatorsPaginationsGraph(req: any): Observable<any> {
    return this.http
      .post<any>(
        environment.BASE_URL2 + `/indicators/pagination/graph`,
        req
      )
      .pipe(retry(0), catchError(this.errorHandl));
  }

  getIndicatorsPaginationsTable(req: any): Observable<any> {
    return this.http
      .post<any>(
        environment.BASE_URL2 + `/indicators/pagination/table`,
        req
      )
      .pipe(retry(0), catchError(this.errorHandl));
  }
  getMetrics(): Observable<any> {
    return this.http
      .post<any>(
        environment.BASE_URL + `/indicators/global/metric`,
        null
      )
      .pipe(retry(0), catchError(this.errorHandl));
  }

  getSelectRows(): Observable<any> {
    return this.http
      .post<any>(
        environment.BASE_URL + `/indicators/consolidated/select_rows`,
        null
      )
      .pipe(retry(0), catchError(this.errorHandl));
  }

  getColums(table) {
    return this.http.get<any>(environment.BASE_URL + `/indicators/table_columns?table_name=${table}`)
      .pipe(
        retry(0)
      )
  }

  ordenar(data, parametro, exclude?: boolean,adicionalvalues?) {
    let negativearr = []
    let indexed = []
    data.sort(function (a, b) {
      if (a[parametro] > b[parametro]) {
        return 1;
      }
      if (a[parametro] < b[parametro]) {
        return -1;
      }
      return 0;
    });
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      if (element[parametro] == -1) {
        if (exclude) {
          if (element.enable_column || element.static_column || adicionalvalues.includes(element.name_back)) {
            negativearr.push(element)
          }
        } else {
          negativearr.push(element);
        }

      } else {
        if (exclude) {
          if (element.enable_column || element.static_column || adicionalvalues.includes(element.name_back)) {
            indexed.push(element)
          }
        } else {
          indexed.push(element);
        }

      }
    }
    let re = (indexed.concat(negativearr))
    return re
  }

  getCrecimientosSku(req: any) {
    return this.http
      .get(
        environment.BASE_URL2 + `/indicators/increase/table/` + req
      )
      .pipe(retry(0), catchError(this.errorHandl));
  }

  getConsolidateSku(req: any) {
    return this.http
      .get(
        environment.BASE_URL2 + `/indicators/consolidated/excel/` + req
      )
      .pipe(retry(0), catchError(this.errorHandl));
  }


  GetCampaignsimulation(req: any) {
    return this.http
      .post<any>(
        environment.BASE_URL2 + `/indicators/global/campaign`,
        req
      )
      .pipe(retry(0), catchError(this.errorHandl))
      .pipe(retry(0), catchError(this.errorHandl));
  }

  getDateUpdate() {
    return this.http
      .post<any>(
        environment.BASE_URL2 + `/indicators/evolution/etl_load_date`,
        {}
      )
      .pipe(retry(0), catchError(this.errorHandl))
      .pipe(retry(0), catchError(this.errorHandl));
  }

  // Error handling
  errorHandl(error) {
    let errorMessage = "";

    return throwError(errorMessage);
  }
}
