import { createReducer, on } from '@ngrx/store';
import { campaingSelected } from './campaing.actions';

export const initialState: any = { campaing: null };

const _campaingReducer = createReducer(
    initialState,
    on(campaingSelected, (state, action) => {
        return { campaing: action.campaing }
    })
);

export function campaingReducer(state, action) {
    return _campaingReducer(state, action);
}