import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as crypto from "crypto-js";

const TOKEN_KEY = environment.TOKEN_KEY;
const USER_KEY = environment.USER_KEY;
const PERMISSIONS_KEY = 'permissions'

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  signOut() {
    window.sessionStorage.clear();
  }

  public saveToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user) {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    let encrypted = crypto.AES.encrypt(JSON.stringify({ user }), environment.SITE_KEY).toString();
    window.localStorage.setItem(USER_KEY, encrypted);
  }

  public savePermissionsStorage(permissions) {
    window.sessionStorage.removeItem(PERMISSIONS_KEY);
    window.sessionStorage.setItem(PERMISSIONS_KEY, JSON.stringify(permissions));
  }

  public getUser() {
    return JSON.parse(sessionStorage.getItem(USER_KEY));
  }

  getPermissions() {
    return JSON.parse(sessionStorage.getItem(PERMISSIONS_KEY))
  }
}